import { useEffect, useState } from "react"
import Spinner from "../../components/Spinner";
import { IoChevronForward, IoCloseCircle, IoReload } from "react-icons/io5";
import Wrapper from "./Wrapper";
import { message, PermiteSoloNumeros } from "../../utils/Funciones";
import { useDispatch, useSelector } from "react-redux";
import ImagesPreviewBanner2 from "../../components/ImagesPreviewBanner2";
import { SET_TO_DEFAULT_RESPONSE, updateConfiguration } from "../../store/features/slices/userSlice";
import { Button, FormControl, IconButton, MenuItem, Select, Tooltip } from "@mui/material";
import Socket, { authSocket, logoutSocket } from '../../utils/Socket';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MuiTelInput } from "mui-tel-input";
import Switch from "../../components/switch";
import { FaPlus } from "react-icons/fa";
import { v4 as uuidv4 } from 'uuid';

const Configuration = ({ socket }) => {
    const dispatch = useDispatch();
    const { isMutation, configuration: configurations } = useSelector((state) => state.userReducer);
    const { isMutation: isMutationCategories, subcategories } = useSelector((state) => state.categoryReducer);
    const [section, setSection] = useState('sistema')
    const [configuration, setConfiguration] = useState({
        _id: "",
        appKey: "",
        name: "",
        logo: "",
        slider1: '',
        slider2: '',
        slider3: '',
        slider4: '',
        slider5: '',
        login: false,
        register: false,
        buyservice: false,
        buycredits: false,
        changepassword: false,
        changeavatar: false,
        maintenance: false,
        howmuchforreseller: 0,
        howmuchtimeforeditpassworddays: 0,
        canrecoverpassword: false,
        sliders: [],
        autodeleteusers: {
            autodelete: true,
            days: 15
        },
        autodeleteventas: {
            autodelete: true,
            days: 30,
            targetIds: []
        },
        autodeletecuentas: {
            autodelete: true,
            days: 30,
            targetIds: []
        },
        social: {
            email: "",
            facebook: "",
            tiktok: "",
            instagram: "",
            telegram: "",
            youtube: "",
            whatsapp: "",
            location: ""
        },
        vip: {
            vip1: {
                price: 12,
                enabled: true,
                eslogan: "",
                namefeature: "",
                features: []
            },
            vip2: {
                price: 60,
                enabled: true,
                eslogan: "",
                namefeature: "",
                features: []
            },
            vip3: {
                price: 100,
                enabled: true,
                eslogan: "",
                namefeature: "",
                features: []
            }
        }
    })

    useEffect(() => {
        setConfiguration({
            ...configuration,
            _id: configurations._id,
            appKey: configurations.appKey,
            name: configurations.name,
            logo: configurations.logo,
            slider1: configurations.sliders[0]?.url,
            slider2: configurations.sliders[1]?.url,
            slider3: configurations.sliders[2]?.url,
            slider4: configurations.sliders[3]?.url,
            slider5: configurations.sliders[4]?.url,
            login: configurations.login,
            register: configurations.register,
            buyservice: configurations.buyservice,
            buycredits: configurations.buycredits,
            changepassword: configurations.changepassword,
            changeavatar: configurations.changeavatar,
            maintenance: configurations.maintenance,
            howmuchforreseller: configurations.howmuchforreseller,
            howmuchtimeforeditpassworddays: configurations.howmuchtimeforeditpassworddays,
            canrecoverpassword: configurations.canrecoverpassword,
            sliders: configurations.sliders,
            autodeleteusers: configurations?.autodeleteusers,
            autodeleteventas: configurations?.autodeleteventas,
            autodeletecuentas: configurations?.autodeletecuentas,
            social: configurations?.social,
            vip: {
                vip1: {
                    price: configurations?.vip?.vip1?.price,
                    enabled: configurations?.vip?.vip1?.enabled,
                    eslogan: configurations?.vip?.vip1?.eslogan,
                    namefeature: "",
                    features: configurations?.vip?.vip1?.features || []
                },
                vip2: {
                    price: configurations?.vip?.vip2?.price,
                    enabled: configurations?.vip?.vip2?.enabled,
                    eslogan: configurations?.vip?.vip2?.eslogan,
                    namefeature: "",
                    features: configurations?.vip?.vip2?.features || []
                },
                vip3: {
                    price: configurations?.vip?.vip3?.price,
                    enabled: configurations?.vip?.vip3?.enabled,
                    eslogan: configurations?.vip?.vip3?.eslogan,
                    namefeature: "",
                    features: configurations?.vip?.vip3?.features || []
                }
            }
        })
    }, [configurations])

    const onChangeHandle = (e) => {
        setConfiguration({ ...configuration, [e.target.name]: e.target.value })
    }

    const [preview, setPreview] = useState({
        logo: '',
        slider1: '',
        slider2: '',
        slider3: '',
        slider4: '',
        slider5: ''
    })

    const imageHandle = e => {
        if (e.target.files.length !== 0) {
            setConfiguration({ ...configuration, [e.target.name]: e.target.files[0] });
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview({ ...preview, [e.target.name]: reader.result })
            }
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const onSubmitUpdateConfiguration = (e) => {
        e.preventDefault();
        const errors = []
        if (configuration.name.trim().length < 1) {
            errors.push({ message: `nombre de la empresa es requerido` })
        }

        if (configuration.howmuchforreseller.toString().trim().length < 1) {
            errors.push({ message: `créditos mínimos para reseller es requerido` })
        }

        if (configuration.howmuchtimeforeditpassworddays.toString().trim().length < 1) {
            errors.push({ message: `días para poder restablecer la contraseña es requerido` })
        }

        if (errors.length === 0) {
            const configcurrent = {
                _id: configurations._id,
                appKey: configurations.appKey,
                name: configurations.name,
                logo: configurations.logo,
                slider1: configurations.sliders[0]?.url,
                slider2: configurations.sliders[1]?.url,
                slider3: configurations.sliders[2]?.url,
                slider4: configurations.sliders[3]?.url,
                slider5: configurations.sliders[4]?.url,
                login: configurations.login,
                register: configurations.register,
                buyservice: configurations.buyservice,
                buycredits: configurations.buycredits,
                changepassword: configurations.changepassword,
                changeavatar: configurations.changeavatar,
                maintenance: configurations.maintenance,
                howmuchforreseller: configurations.howmuchforreseller,
                howmuchtimeforeditpassworddays: configurations.howmuchtimeforeditpassworddays,
                canrecoverpassword: configurations.canrecoverpassword
            }

            if (JSON.stringify(configuration) !== JSON.stringify(configcurrent)) {
                configuration.sliders = configurations.sliders
                const formData = new FormData();
                formData.append('data', JSON.stringify(configuration));
                formData.append('current', JSON.stringify(configurations));
                formData.append('image1', configuration.logo)
                formData.append('image2', configuration.slider1)
                formData.append('image3', configuration.slider2)
                formData.append('image4', configuration.slider3)
                formData.append('image5', configuration.slider4)
                formData.append('image6', configuration.slider5)
                dispatch(updateConfiguration(formData))
            } else {
                message('No hubo ningún cambio.', 'error', 4)
            }

        } else {
            errors.map((err) => {
                message(err.message, 'error', 4)
            });
        }

    }

    useEffect(() => {
        if (isMutation.updated) {
            message(isMutation.message, 'success', 5)
            Socket.emit('uConfiguration', { newConfiguration: isMutation.extra.newConfiguration })
            setPreview({ ...preview, logo: '', slider1: '', slider2: '', slider3: '', slider4: '', slider5: '' })
            setConfiguration({ ...configuration, logo: '', slider1: '', slider2: '', slider3: '', slider4: '', slider5: '' })
            dispatch(SET_TO_DEFAULT_RESPONSE())
        }
    }, [isMutation.updated])

    return (
        <Wrapper>
            {/* HEADER PAGES */}
            <div className="fixed ml-7 md:ml-0 pl-3 md:pl-3 lg:pl-3 h-8 top-2 md:top-11 z-30 flex items-center w-full md:w-8/12 text-brand-900 dark:text-brand-100 text-[1.2rem] md:text-[1.8rem] lg:text-[2rem] font-semibold">
                Gestionar servidor
            </div>

            <div className="fixed mt-8 z-30 flex items-center border-b-2 border-b-brand-300 dark:border-b-brand-800 text-brand-950 dark:text-brand-50 gap-1 bg-brand-200 dark:bg-brand-950 pt-2">
                <div onClick={() => setSection('sistema')} className={`p-4 rounded-t-xl bg-brand-300 dark:bg-brand-900 ${section === 'sistema' && 'bg-lightPrimary dark:bg-darkPrimary text-white'} cursor-pointer`}>Sistema</div>
                <div onClick={() => setSection('accesos')} className={`p-4 rounded-t-xl bg-brand-300 dark:bg-brand-900 ${section === 'accesos' && 'bg-lightPrimary dark:bg-darkPrimary text-white'} cursor-pointer`}>Accesos</div>
                <div onClick={() => setSection('vip')} className={`p-4 rounded-t-xl bg-brand-300 dark:bg-brand-900 ${section === 'vip' && 'bg-lightPrimary dark:bg-darkPrimary text-white'} cursor-pointer`}>VIP</div>
                <div onClick={() => setSection('galeria')} className={`p-4 rounded-t-xl bg-brand-300 dark:bg-brand-900 ${section === 'galeria' && 'bg-lightPrimary dark:bg-darkPrimary text-white'} cursor-pointer`}>Galeria</div>
            </div>

            <div className="flex flex-wrap mt-24 gap-4">
                <form className="w-full xl:w-8/12 p-[2px] md:p-[2px] lg:p-[0px]" onSubmit={onSubmitUpdateConfiguration}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5 mt-5">
                        {
                            section === 'sistema' &&
                            <>
                                <div className="w-full">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">APP KEY</label>
                                    <input name="appKey" className="custom-style-input"
                                        autoComplete="off"
                                        disabled
                                        value={configuration.appKey}
                                        type="text"
                                    />
                                </div>

                                <div className="w-full">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre de la empresa</label>
                                    <input autoFocus name="name" className="custom-style-input"
                                        autoComplete="off"
                                        required
                                        value={configuration.name}
                                        onChange={onChangeHandle}
                                        type="text"
                                    />
                                </div>

                                <div className="w-full">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Créditos mínimos para reseller</label>
                                    <input name="howmuchforreseller" className="custom-style-input"
                                        autoComplete="off"
                                        required
                                        value={configuration.howmuchforreseller}
                                        onChange={onChangeHandle}
                                        type="number"
                                        min={0}
                                        onKeyPress={PermiteSoloNumeros}
                                    />
                                </div>

                                <div className="w-full">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Días para poder restablecer contraseña</label>
                                    <input name="howmuchtimeforeditpassworddays" className="custom-style-input"
                                        autoComplete="off"
                                        required
                                        value={configuration.howmuchtimeforeditpassworddays}
                                        onChange={onChangeHandle}
                                        type="number"
                                        min={0}
                                        onKeyPress={PermiteSoloNumeros}
                                    />
                                </div>

                                <div className="w-full col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Autoeliminar usuarios</label>
                                    <div class="flex items-center p-3 mb-3 text-sm rounded-lg bg-lightPrimary/10 dark:bg-darkPrimary/10 text-lightPrimary dark:text-darkPrimary" role="alert">
                                        <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                        </svg>
                                        <span class="sr-only">Info</span>
                                        <div>
                                            <span class="font-bold uppercase">Importante!</span> Se autoeliminarán los usuarios que no tengan créditos y no hayan ingresado durante la cantidad de días que configures.
                                        </div>
                                    </div>
                                    <div className="flex items-cente flex-wrap">
                                        <div className="w-[100px] mr-5">
                                            <label className="text-brand-900 dark:text-brand-200 text-sm"><span className="font-semibold">{configuration.autodeleteusers.autodelete ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">No</span>}</span></label>
                                            <div className="h-10 mt-3">
                                                <Switch
                                                    value={configuration.autodeleteusers.autodelete}
                                                    onChange={() => setConfiguration({ ...configuration, autodeleteusers: { ...configuration.autodeleteusers, autodelete: !configuration.autodeleteusers.autodelete } })}
                                                />
                                            </div>
                                        </div>
                                        <div className="">
                                            <label for="first_name" class="block mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Cantidad de días</label>
                                            <input name="name" className="custom-style-input max-w-[90px]"
                                                autoComplete="off"
                                                required
                                                value={configuration.autodeleteusers.days}
                                                onChange={(e) => setConfiguration({ ...configuration, autodeleteusers: { ...configuration.autodeleteusers, days: e.target.value } })}
                                                type="text"
                                                onKeyPress={PermiteSoloNumeros}
                                                maxLength="3"
                                            />
                                        </div>

                                    </div>
                                </div>

                                <div className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <div className="w-full">
                                        <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Autoeliminar ventas bloqueadas</label>
                                        <div class="flex items-center p-3 mb-3 text-sm rounded-lg bg-lightPrimary/10 dark:bg-darkPrimary/10 text-lightPrimary dark:text-darkPrimary" role="alert">
                                            <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                            </svg>
                                            <span class="sr-only">Info</span>
                                            <div>
                                                <span class="font-bold uppercase">Importante!</span> Se autoeliminarán las ventas que se bloquean cuando expiran y se eliminarán después de la cantidad de días que configures y estén en la lista filtrada.
                                            </div>
                                        </div>
                                        <div className="flex items-cente flex-wrap">
                                            <div className="w-[100px] mr-5">
                                                <label className="text-brand-900 dark:text-brand-200 text-sm"><span className="font-semibold">{configuration.autodeleteventas.autodelete ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">No</span>}</span></label>
                                                <div className="h-10 mt-3">
                                                    <Switch
                                                        value={configuration.autodeleteventas.autodelete}
                                                        onChange={() => setConfiguration({ ...configuration, autodeleteventas: { ...configuration.autodeleteventas, autodelete: !configuration.autodeleteventas.autodelete } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="">
                                                <label for="first_name" class="block mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Cantidad de días</label>
                                                <input name="name" className="custom-style-input max-w-[90px]"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.autodeleteventas.days}
                                                    onChange={(e) => setConfiguration({ ...configuration, autodeleteventas: { ...configuration.autodeleteventas, days: e.target.value } })}
                                                    type="text"
                                                    onKeyPress={PermiteSoloNumeros}
                                                    maxLength="3"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Seleccionar filtro</label>
                                                <FormControl fullWidth>
                                                    <Select
                                                        name="subcategory"
                                                        className="flex items-center"
                                                        variant="outlined"
                                                    >
                                                        {!isMutationCategories.loading && subcategories.filter((fil) => fil.category.type === 'Streaming' && !configuration.autodeleteventas.targetIds.some(itemB => itemB === fil._id)).map(subcategory => (
                                                            <MenuItem onClick={() => setConfiguration({ ...configuration, autodeleteventas: { ...configuration.autodeleteventas, targetIds: [...configuration.autodeleteventas.targetIds, subcategory._id] } })} value={subcategory._id} key={subcategory._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${subcategory.logo}`} className="mr-2 rounded-full" />{subcategory.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full max-h-[300px] min-h-[300px] overflow-y-scroll bg-white dark:bg-brand-900 rounded-lg p-2">
                                        <label for="first_name" class="flex items-center mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Lista de filtrados <span className="ml-2 flex min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] items-center justify-center p-2 bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">{configuration.autodeleteventas.targetIds.length}</span></label>
                                        {
                                            configuration.autodeleteventas.targetIds.map((item) => {
                                                let logo = ""
                                                let name = ""
                                                !isMutationCategories.loading && subcategories.find((subcat) => {
                                                    if (subcat._id === item) {
                                                        logo = subcat.logo
                                                        name = subcat.name
                                                    }
                                                })
                                                return (
                                                    <div key={item} className="w-full flex items-center bg-lightPrimary/20 dark:bg-darkPrimary/20 rounded-lg p-3 mb-3">
                                                        <div className="flex items-center justify-center">
                                                            <LazyLoadImage width={25} height={25} src={`/images/logos/${logo}`} className="mr-2 h-10 w-10 rounded-full object-cover" />
                                                        </div>
                                                        <div className="w-full flex items-center text-left text-brand-900 dark:text-brand-100 text-sm">
                                                            {name}
                                                        </div>
                                                        <div className="max-w-[35px] min-w-[35px] max-h-[35px] min-h-[35px] flex items-center justify-center">
                                                            <Tooltip title="quitar" arrow={true}>
                                                                <Button onClick={() => setConfiguration({ ...configuration, autodeleteventas: { ...configuration.autodeleteventas, targetIds: configuration.autodeleteventas.targetIds.filter((fil) => fil !== item) } })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                                                    <IoCloseCircle className="w-5 h-5 text-white" />
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <div className="w-full">
                                        <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Autoeliminar cuentas expiradas</label>
                                        <div class="flex items-center p-3 mb-3 text-sm rounded-lg bg-lightPrimary/10 dark:bg-darkPrimary/10 text-lightPrimary dark:text-darkPrimary" role="alert">
                                            <svg class="flex-shrink-0 inline w-8 h-8 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                            </svg>
                                            <span class="sr-only">Info</span>
                                            <div>
                                                <span class="font-bold uppercase">Importante!</span> Se autoeliminarán las cuentas cuando expiran y las ventas de esa cuenta, estas se eliminarán después de la cantidad de días que configures y estén en la lista filtrada.
                                            </div>
                                        </div>
                                        <div className="flex items-cente flex-wrap">
                                            <div className="w-[100px] mr-5">
                                                <label className="text-brand-900 dark:text-brand-200 text-sm"><span className="font-semibold">{configuration.autodeletecuentas.autodelete ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">No</span>}</span></label>
                                                <div className="h-10 mt-3">
                                                    <Switch
                                                        value={configuration.autodeletecuentas.autodelete}
                                                        onChange={() => setConfiguration({ ...configuration, autodeletecuentas: { ...configuration.autodeletecuentas, autodelete: !configuration.autodeletecuentas.autodelete } })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="">
                                                <label for="first_name" class="block mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Cantidad de días</label>
                                                <input name="name" className="custom-style-input max-w-[90px]"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.autodeletecuentas.days}
                                                    onChange={(e) => setConfiguration({ ...configuration, autodeletecuentas: { ...configuration.autodeletecuentas, days: e.target.value } })}
                                                    type="text"
                                                    onKeyPress={PermiteSoloNumeros}
                                                    maxLength="3"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Seleccionar filtro</label>
                                                <FormControl fullWidth>
                                                    <Select
                                                        name="subcategory"
                                                        className="flex items-center"
                                                        variant="outlined"
                                                    >
                                                        {!isMutationCategories.loading && subcategories.filter((fil) => fil.category.type === 'Streaming' && !configuration.autodeletecuentas.targetIds.some(itemB => itemB === fil._id)).map(subcategory => (
                                                            <MenuItem onClick={() => setConfiguration({ ...configuration, autodeletecuentas: { ...configuration.autodeletecuentas, targetIds: [...configuration.autodeletecuentas.targetIds, subcategory._id] } })} value={subcategory._id} key={subcategory._id} className="flex items-center"><LazyLoadImage width={25} height={25} src={`/images/logos/${subcategory.logo}`} className="mr-2 rounded-full" />{subcategory.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full max-h-[300px] min-h-[300px] overflow-y-scroll bg-white dark:bg-brand-900 rounded-lg p-2">
                                        <label for="first_name" class="flex items-center mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white">Lista de filtrados <span className="ml-2 flex min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] items-center justify-center p-2 bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">{configuration.autodeletecuentas.targetIds.length}</span></label>
                                        {
                                            configuration.autodeletecuentas.targetIds.map((item) => {
                                                let logo = ""
                                                let name = ""
                                                !isMutationCategories.loading && subcategories.find((subcat) => {
                                                    if (subcat._id === item) {
                                                        logo = subcat.logo
                                                        name = subcat.name
                                                    }
                                                })
                                                return (
                                                    <div key={item} className="w-full flex items-center bg-lightPrimary/20 dark:bg-darkPrimary/20 rounded-lg p-3 mb-3">
                                                        <div className="flex items-center justify-center">
                                                            <LazyLoadImage width={25} height={25} src={`/images/logos/${logo}`} className="mr-2 h-10 w-10 rounded-full object-cover" />
                                                        </div>
                                                        <div className="w-full flex items-center text-left text-brand-900 dark:text-brand-100 text-sm">
                                                            {name}
                                                        </div>
                                                        <div className="max-w-[35px] min-w-[35px] max-h-[35px] min-h-[35px] flex items-center justify-center">
                                                            <Tooltip title="quitar" arrow={true}>
                                                                <Button onClick={() => setConfiguration({ ...configuration, autodeletecuentas: { ...configuration.autodeletecuentas, targetIds: configuration.autodeletecuentas.targetIds.filter((fil) => fil !== item) } })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                                                    <IoCloseCircle className="w-5 h-5 text-white" />
                                                                </Button>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>

                                <div className="w-full col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <div className="w-full">
                                        <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Redes sociales</label>
                                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Email</label>
                                                <input name="name" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.social.email}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, email: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Facebook</label>
                                                <input name="name" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.social.facebook}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, facebook: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Tiktok</label>
                                                <input name="name" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.social.tiktok}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, tiktok: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Instagram</label>
                                                <input name="name" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.social.instagram}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, instagram: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Telegram</label>
                                                <input name="name" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.social.telegram}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, telegram: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Youtube</label>
                                                <input name="youtube" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration?.social?.youtube}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, youtube: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Whatsapp de soporte</label>
                                                <MuiTelInput
                                                    inputProps={{ className: "text-brand-900 dark:text-brand-200" }}
                                                    required
                                                    value={configuration.social.whatsapp}
                                                    defaultCountry={'PE'}
                                                    onChange={(newValue, info) => setConfiguration({ ...configuration, social: { ...configuration.social, whatsapp: newValue } })}
                                                    className="custom-style-input"
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Localización</label>
                                                <input name="name" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration?.social?.location}
                                                    onChange={(e) => setConfiguration({ ...configuration, social: { ...configuration.social, location: e.target.value } })}
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            section === 'accesos' &&
                            <>
                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Ingreso? <span className="font-semibold">{configuration.login ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.login}
                                            onChange={() => setConfiguration({ ...configuration, login: !configuration.login })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Registro? <span className="font-semibold">{configuration.register ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.register}
                                            onChange={() => setConfiguration({ ...configuration, register: !configuration.register })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Comprar servicios? <span className="font-semibold">{configuration.buyservice ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.buyservice}
                                            onChange={() => setConfiguration({ ...configuration, buyservice: !configuration.buyservice })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Comprar créditos? <span className="font-semibold">{configuration.buycredits ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.buycredits}
                                            onChange={() => setConfiguration({ ...configuration, buycredits: !configuration.buycredits })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Cambiar contraseña? <span className="font-semibold">{configuration.changepassword ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.changepassword}
                                            onChange={() => setConfiguration({ ...configuration, changepassword: !configuration.changepassword })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Cambiar perfil? <span className="font-semibold">{configuration.changeavatar ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.changeavatar}
                                            onChange={() => setConfiguration({ ...configuration, changeavatar: !configuration.changeavatar })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Recuperar contraseña? <span className="font-semibold">{configuration.canrecoverpassword ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">permitido</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">bloqueado</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.canrecoverpassword}
                                            onChange={() => setConfiguration({ ...configuration, canrecoverpassword: !configuration.canrecoverpassword })}
                                        />
                                    </div>
                                </div>

                                <div className="w-full">
                                    <label className="text-brand-900 dark:text-brand-200 text-sm">Mantenimiento pagina de usuarios? <span className="font-semibold">{configuration.maintenance ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">no</span>}</span></label>
                                    <div className="h-10 mt-3">
                                        <Switch
                                            value={configuration.maintenance}
                                            onChange={() => setConfiguration({ ...configuration, maintenance: !configuration.maintenance })}
                                        />
                                    </div>
                                </div>

                            </>
                        }


                        {
                            section === 'vip' &&
                            <>
                                <div className="w-full col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <div className="p-2 relative bg-gradient-to-t from-[#553617] to-[#CD7F32] inline-block text-transparent bg-clip-text text-2xl font-bold">
                                        VIP BRONCE
                                    </div>
                                    <div className="flex items-cente flex-wrap gap-3">
                                        <div className="w-full p-2">
                                            <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado <span className="font-semibold">{configuration.vip.vip1.enabled ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">No</span>}</span></label>
                                            <div className="h-10 mt-3">
                                                <Switch
                                                    value={configuration.vip.vip1.enabled}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip1: {
                                                                ...configuration.vip.vip1,
                                                                enabled: !configuration.vip.vip1.enabled
                                                            }
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3 mb-5 p-2">
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Precio</label>
                                                <input name="pricevip1" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.vip.vip1.price}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip1: {
                                                                ...configuration.vip.vip1,
                                                                price: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="number"
                                                    min={0}
                                                    onKeyPress={PermiteSoloNumeros}
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Eslogan</label>
                                                <input name="esloganvip1" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.vip.vip1.eslogan}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip1: {
                                                                ...configuration.vip.vip1,
                                                                eslogan: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    maxLength={50}
                                                />
                                            </div>
                                        </div>

                                        <div className="w-full max-h-[300px] min-h-[300px] overflow-y-scroll rounded-lg p-2">
                                            <label for="first_name" class="flex items-center mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white gap-2">Caracteristicas <span className="ml-2 flex min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] items-center justify-center p-2 bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">{configuration.vip.vip1.features.length}</span> Max. 7</label>
                                            <div className="relative w-full md:w-[calc(50%-6px)]">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre</label>
                                                <input name="namefeaturevip1" className="custom-style-input"
                                                    autoComplete="off"
                                                    value={configuration.vip.vip1.namefeature}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip1: {
                                                                ...configuration.vip.vip1,
                                                                namefeature: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    maxLength={50}
                                                />
                                                <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                                    <Button disabled={(configuration.vip.vip1.namefeature.length < 1 || configuration.vip?.vip1?.features.length === 7) ? true : false}
                                                        onClick={() => setConfiguration({
                                                            ...configuration,
                                                            vip: {
                                                                ...configuration.vip,
                                                                vip1: {
                                                                    ...configuration.vip.vip1,
                                                                    namefeature: "",
                                                                    features: [
                                                                        ...(configuration.vip.vip1.features || []),
                                                                        { _id: uuidv4(), name: configuration.vip.vip1.namefeature }
                                                                    ]
                                                                }
                                                            }
                                                        })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-lightPrimary !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-lightPrimary/80 active:!bg-lightPrimary/60 dark:!bg-darkPrimary dark:!text-white dark:hover:!bg-darkPrimary/80 dark:active:!bg-darkPrimary/60">
                                                        <FaPlus className="text-white text-2xl" />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-5 mt-5">
                                                {
                                                    configuration.vip?.vip1?.features?.map((item) => {
                                                        return (
                                                            <div key={item._id} className="w-full flex items-center bg-lightPrimary/20 dark:bg-darkPrimary/20 rounded-lg p-3 mb-3">
                                                                <div className="w-full flex items-center text-left text-brand-900 dark:text-brand-100 text-sm">
                                                                    {item.name}
                                                                </div>
                                                                <div className="max-w-[35px] min-w-[35px] max-h-[35px] min-h-[35px] flex items-center justify-center">
                                                                    <Tooltip title="quitar" arrow={true}>
                                                                        <Button onClick={() => setConfiguration({
                                                                            ...configuration,
                                                                            vip: {
                                                                                ...configuration.vip,
                                                                                vip1: {
                                                                                    ...configuration.vip.vip1,
                                                                                    namefeature: "",
                                                                                    features: configuration.vip.vip1.features.filter(feature => feature._id !== item._id)
                                                                                }
                                                                            }
                                                                        })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                                                            <IoCloseCircle className="w-5 h-5 text-white" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="w-full col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <div className="p-2 relative bg-gradient-to-t from-[#808080] to-[#C0C0C0] inline-block text-transparent bg-clip-text text-2xl font-bold">
                                        VIP PLATA
                                    </div>
                                    <div className="flex items-cente flex-wrap gap-3">
                                        <div className="w-full p-2">
                                            <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado <span className="font-semibold">{configuration.vip.vip2.enabled ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">No</span>}</span></label>
                                            <div className="h-10 mt-3">
                                                <Switch
                                                    value={configuration.vip.vip2.enabled}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip2: {
                                                                ...configuration.vip.vip2,
                                                                enabled: !configuration.vip.vip2.enabled
                                                            }
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3 mb-5 p-2">
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Precio</label>
                                                <input name="pricevip2" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.vip.vip2.price}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip2: {
                                                                ...configuration.vip.vip2,
                                                                price: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="number"
                                                    min={0}
                                                    onKeyPress={PermiteSoloNumeros}
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Eslogan</label>
                                                <input name="esloganvip2" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.vip.vip2.eslogan}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip2: {
                                                                ...configuration.vip.vip2,
                                                                eslogan: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    maxLength={50}
                                                />
                                            </div>
                                        </div>

                                        <div className="w-full max-h-[300px] min-h-[300px] overflow-y-scroll rounded-lg p-2">
                                            <label for="first_name" class="flex items-center mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white gap-2">Caracteristicas <span className="ml-2 flex min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] items-center justify-center p-2 bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">{configuration.vip.vip2.features.length}</span> Max. 7</label>
                                            <div className="relative w-full md:w-[calc(50%-6px)]">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre</label>
                                                <input name="namefeaturevip2" className="custom-style-input"
                                                    autoComplete="off"
                                                    value={configuration.vip.vip2.namefeature}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip2: {
                                                                ...configuration.vip.vip2,
                                                                namefeature: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    maxLength={50}
                                                />
                                                <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                                    <Button disabled={(configuration.vip.vip2.namefeature.length < 1 || configuration.vip?.vip2?.features.length === 7) ? true : false}
                                                        onClick={() => setConfiguration({
                                                            ...configuration,
                                                            vip: {
                                                                ...configuration.vip,
                                                                vip2: {
                                                                    ...configuration.vip.vip2,
                                                                    namefeature: "",
                                                                    features: [
                                                                        ...(configuration.vip.vip2.features || []),
                                                                        { _id: uuidv4(), name: configuration.vip.vip2.namefeature }
                                                                    ]
                                                                }
                                                            }
                                                        })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-lightPrimary !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-lightPrimary/80 active:!bg-lightPrimary/60 dark:!bg-darkPrimary dark:!text-white dark:hover:!bg-darkPrimary/80 dark:active:!bg-darkPrimary/60">
                                                        <FaPlus className="text-white text-2xl" />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-5 mt-5">
                                                {
                                                    configuration.vip?.vip2?.features?.map((item) => {
                                                        return (
                                                            <div key={item._id} className="w-full flex items-center bg-lightPrimary/20 dark:bg-darkPrimary/20 rounded-lg p-3 mb-3">
                                                                <div className="w-full flex items-center text-left text-brand-900 dark:text-brand-100 text-sm">
                                                                    {item.name}
                                                                </div>
                                                                <div className="max-w-[35px] min-w-[35px] max-h-[35px] min-h-[35px] flex items-center justify-center">
                                                                    <Tooltip title="quitar" arrow={true}>
                                                                        <Button onClick={() => setConfiguration({
                                                                            ...configuration,
                                                                            vip: {
                                                                                ...configuration.vip,
                                                                                vip2: {
                                                                                    ...configuration.vip.vip2,
                                                                                    namefeature: "",
                                                                                    features: configuration.vip.vip2.features.filter(feature => feature._id !== item._id)
                                                                                }
                                                                            }
                                                                        })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                                                            <IoCloseCircle className="w-5 h-5 text-white" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="w-full col-span-1 md:col-span-2 lg:col-span-2 border-2 border-lightPrimary/20 dark:border-darkPrimary/20 rounded-lg p-2">
                                    <div className="p-2 relative bg-gradient-to-t from-[#816e00] to-[#FFD700] inline-block text-transparent bg-clip-text text-2xl font-bold">
                                        VIP ORO
                                    </div>
                                    <div className="flex items-cente flex-wrap gap-3">
                                        <div className="w-full p-2">
                                            <label className="text-brand-900 dark:text-brand-200 text-sm">Habilitado <span className="font-semibold">{configuration.vip.vip3.enabled ? <span className="text-green-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">Si</span> : <span className="text-red-500 uppercase bg-brand-200 dark:bg-brand-900 p-2 rounded-lg">No</span>}</span></label>
                                            <div className="h-10 mt-3">
                                                <Switch
                                                    value={configuration.vip.vip3.enabled}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip3: {
                                                                ...configuration.vip.vip3,
                                                                enabled: !configuration.vip.vip3.enabled
                                                            }
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3 mb-5 p-2">
                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Precio</label>
                                                <input name="pricevip3" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.vip.vip3.price}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip3: {
                                                                ...configuration.vip.vip3,
                                                                price: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="number"
                                                    min={0}
                                                    onKeyPress={PermiteSoloNumeros}
                                                />
                                            </div>

                                            <div className="w-full">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Eslogan</label>
                                                <input name="esloganvip3" className="custom-style-input"
                                                    autoComplete="off"
                                                    required
                                                    value={configuration.vip.vip3.eslogan}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip3: {
                                                                ...configuration.vip.vip3,
                                                                eslogan: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    maxLength={50}
                                                />
                                            </div>
                                        </div>

                                        <div className="w-full max-h-[300px] min-h-[300px] overflow-y-scroll rounded-lg p-2">
                                            <label for="first_name" class="flex items-center mb-1 mt-1 text-sm font-medium text-brand-900 dark:text-white gap-2">Caracteristicas <span className="ml-2 flex min-w-[35px] max-w-[35px] min-h-[35px] max-h-[35px] items-center justify-center p-2 bg-brand-300 dark:bg-brand-800 text-brand-800 dark:text-brand-200 rounded-full">{configuration.vip.vip3.features.length}</span> Max. 7</label>
                                            <div className="relative w-full md:w-[calc(50%-6px)]">
                                                <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Nombre</label>
                                                <input name="namefeaturevip3" className="custom-style-input"
                                                    autoComplete="off"
                                                    value={configuration.vip.vip3.namefeature}
                                                    onChange={(e) => setConfiguration({
                                                        ...configuration,
                                                        vip: {
                                                            ...configuration.vip,
                                                            vip3: {
                                                                ...configuration.vip.vip3,
                                                                namefeature: e.target.value
                                                            }
                                                        }
                                                    })}
                                                    type="text"
                                                    maxLength={50}
                                                />
                                                <div class="absolute inset-y-4 mt-7 right-0 pr-3 flex items-center text-sm leading-5">
                                                    <Button disabled={(configuration.vip.vip3.namefeature.length < 1 || configuration.vip?.vip3?.features.length === 7) ? true : false}
                                                        onClick={() => setConfiguration({
                                                            ...configuration,
                                                            vip: {
                                                                ...configuration.vip,
                                                                vip3: {
                                                                    ...configuration.vip.vip3,
                                                                    namefeature: "",
                                                                    features: [
                                                                        ...(configuration.vip.vip3.features || []),
                                                                        { _id: uuidv4(), name: configuration.vip.vip3.namefeature }
                                                                    ]
                                                                }
                                                            }
                                                        })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-lightPrimary !px-2 !py-2 !text-base font-medium !text-white transition !duration-200 hover:!bg-lightPrimary/80 active:!bg-lightPrimary/60 dark:!bg-darkPrimary dark:!text-white dark:hover:!bg-darkPrimary/80 dark:active:!bg-darkPrimary/60">
                                                        <FaPlus className="text-white text-2xl" />
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 mb-5 mt-5">
                                                {
                                                    configuration.vip?.vip3?.features?.map((item) => {
                                                        return (
                                                            <div key={item._id} className="w-full flex items-center bg-lightPrimary/20 dark:bg-darkPrimary/20 rounded-lg p-3 mb-3">
                                                                <div className="w-full flex items-center text-left text-brand-900 dark:text-brand-100 text-sm">
                                                                    {item.name}
                                                                </div>
                                                                <div className="max-w-[35px] min-w-[35px] max-h-[35px] min-h-[35px] flex items-center justify-center">
                                                                    <Tooltip title="quitar" arrow={true}>
                                                                        <Button onClick={() => setConfiguration({
                                                                            ...configuration,
                                                                            vip: {
                                                                                ...configuration.vip,
                                                                                vip3: {
                                                                                    ...configuration.vip.vip3,
                                                                                    namefeature: "",
                                                                                    features: configuration.vip.vip3.features.filter(feature => feature._id !== item._id)
                                                                                }
                                                                            }
                                                                        })} variant="contained" sx={{ maxWidth: 35, minWidth: 35, maxHeight: 35, minHeight: 35 }} className="rounded-xl !bg-red-500 !px-2 !py-2 !text-white transition !duration-200 hover:!bg-red-600 active:!bg-red-700 dark:!bg-red-400 dark:!text-white dark:hover:!bg-red-300 dark:active:!bg-red-200">
                                                                            <IoCloseCircle className="w-5 h-5 text-white" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        }


                        {
                            section === 'galeria' &&
                            <>
                                <div className="w-full relative lg:col-span-2">
                                    <label for="first_name" class="block mb-4 text-base font-medium text-brand-900 dark:text-white">Logo de la empresa</label>
                                    <div className="grid grid-cols-1">
                                        <div className="w-full flex flex-wrap gap-1">
                                            <div className="w-full md:w-[calc(40%-4px)] lg:w-[calc(40%-4px)]">
                                                <label htmlFor="logo" class="flex flex-col items-center justify-center w-full max-h-[140px] min-h-[140px] rounded-lg cursor-pointer bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Logo</label>
                                                    <div class="flex flex-col items-center justify-center">
                                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        <p class="text-xs text-brand-900 dark:text-brand-200">PNG, WEBP O JPEG (MAX. 1920px*1080px)</p>
                                                    </div>
                                                    <input id="logo" accept="image/png,image/jpeg,image/webp" type="file" name="logo" class="hidden" onChange={imageHandle} />
                                                </label>
                                            </div>
                                            <div class="w-full md:w-[60%] lg:w-[60%] flex justify-center items-center bg-white dark:bg-brand-900 min-w-[160px] max-h-[140px] min-h-[140px] rounded-lg">
                                                <div className="rounded-sm flex items-center justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <img src={`/images/logos/${configurations.logo}`} id='prelogo' className="object-cover max-w-[160px] min-w-[160px] max-w-[160px] min-w-[90px] max-h-[90px]" />
                                                        </div>
                                                    </div>
                                                    {preview.logo && <IoChevronForward className="text-brand-300" size={32} />}
                                                    <ImagesPreviewBanner2 url={preview.logo} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full relative lg:col-span-2">
                                    <label for="first_name" class="block mb-4 text-base font-medium text-brand-900 dark:text-white">Sliders</label>
                                    <div className="grid grid-cols-1 gap-4">
                                        <div className="w-full flex flex-wrap gap-1">
                                            <div className="w-full md:w-[calc(40%-4px)] lg:w-[calc(40%-4px)]">
                                                <label htmlFor="slider1" class="flex flex-col items-center justify-center w-full max-h-[140px] min-h-[140px] rounded-lg cursor-pointer bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Slider 1</label>
                                                    <div class="flex flex-col items-center justify-center">
                                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        <p class="text-xs text-brand-900 dark:text-brand-200">PNG, WEBP O JPEG (MAX. 1920px*1080px)</p>
                                                    </div>
                                                    <input id="slider1" accept="image/png,image/jpeg,image/webp" type="file" name="slider1" class="hidden" onChange={imageHandle} />
                                                </label>
                                            </div>
                                            <div class="w-full md:w-[60%] lg:w-[60%] flex justify-center items-center bg-white dark:bg-brand-900 min-w-[160px] max-h-[140px] min-h-[140px] rounded-lg">
                                                <div className="rounded-sm flex items-center justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <img src={`/images/logos/${configurations.sliders[0].url}`} id='prelogo' className="object-cover max-w-[160px] min-w-[160px] max-w-[160px] min-w-[160px] max-h-[90px] min-h-[90px]" />
                                                        </div>
                                                    </div>
                                                    {preview.slider1 && <IoChevronForward className="text-brand-300" size={32} />}
                                                    <ImagesPreviewBanner2 url={preview.slider1} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex flex-wrap gap-1">
                                            <div className="w-full md:w-[calc(40%-4px)] lg:w-[calc(40%-4px)]">
                                                <label htmlFor="slider2" class="flex flex-col items-center justify-center w-full max-h-[140px] min-h-[140px] rounded-lg cursor-pointer bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Slider 2</label>
                                                    <div class="flex flex-col items-center justify-center">
                                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        <p class="text-xs text-brand-900 dark:text-brand-200">PNG, WEBP O JPEG (MAX. 1920px*1080px)</p>
                                                    </div>
                                                    <input id="slider2" accept="image/png,image/jpeg,image/webp" type="file" name="slider2" class="hidden" onChange={imageHandle} />
                                                </label>
                                            </div>
                                            <div class="w-full md:w-[60%] lg:w-[60%] flex justify-center items-center bg-white dark:bg-brand-900 min-w-[160px] max-h-[140px] min-h-[140px] rounded-lg">
                                                <div className="rounded-sm flex items-center justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <img src={`/images/logos/${configurations.sliders[1].url}`} id='prelogo' className="object-cover max-w-[160px] min-w-[160px] max-w-[160px] min-w-[160px] max-h-[90px] min-h-[90px]" />
                                                        </div>
                                                    </div>
                                                    {preview.slider2 && <IoChevronForward className="text-brand-300" size={32} />}
                                                    <ImagesPreviewBanner2 url={preview.slider2} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex flex-wrap gap-1">
                                            <div className="w-full md:w-[calc(40%-4px)] lg:w-[calc(40%-4px)]">
                                                <label htmlFor="slider3" class="flex flex-col items-center justify-center w-full max-h-[140px] min-h-[140px] rounded-lg cursor-pointer bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Slider 3</label>
                                                    <div class="flex flex-col items-center justify-center">
                                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        <p class="text-xs text-brand-900 dark:text-brand-200">PNG, WEBP O JPEG (MAX. 1920px*1080px)</p>
                                                    </div>
                                                    <input id="slider3" accept="image/png,image/jpeg,image/webp" type="file" name="slider3" class="hidden" onChange={imageHandle} />
                                                </label>
                                            </div>
                                            <div class="w-full md:w-[60%] lg:w-[60%] flex justify-center items-center bg-white dark:bg-brand-900 min-w-[160px] max-h-[140px] min-h-[140px] rounded-lg">
                                                <div className="rounded-sm flex items-center justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <img src={`/images/logos/${configurations.sliders[2].url}`} id='prelogo' className="object-cover max-w-[160px] min-w-[160px] max-w-[160px] min-w-[160px] max-h-[90px] min-h-[90px]" />
                                                        </div>
                                                    </div>
                                                    {preview.slider3 && <IoChevronForward className="text-brand-300" size={32} />}
                                                    <ImagesPreviewBanner2 url={preview.slider3} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex flex-wrap gap-1">
                                            <div className="w-full md:w-[calc(40%-4px)] lg:w-[calc(40%-4px)]">
                                                <label htmlFor="slider4" class="flex flex-col items-center justify-center w-full max-h-[140px] min-h-[140px] rounded-lg cursor-pointer bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Slider 4</label>
                                                    <div class="flex flex-col items-center justify-center">
                                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        <p class="text-xs text-brand-900 dark:text-brand-200">PNG, WEBP O JPEG (MAX. 1920px*1080px)</p>
                                                    </div>
                                                    <input id="slider4" accept="image/png,image/jpeg,image/webp" type="file" name="slider4" class="hidden" onChange={imageHandle} />
                                                </label>
                                            </div>
                                            <div class="w-full md:w-[60%] lg:w-[60%] flex justify-center items-center bg-white dark:bg-brand-900 min-w-[160px] max-h-[140px] min-h-[140px] rounded-lg">
                                                <div className="rounded-sm flex items-center justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <img src={`/images/logos/${configurations.sliders[3].url}`} id='prelogo' className="object-cover max-w-[160px] min-w-[160px] max-w-[160px] min-w-[160px] max-h-[90px] min-h-[90px]" />
                                                        </div>
                                                    </div>
                                                    {preview.slider4 && <IoChevronForward className="text-brand-300" size={32} />}
                                                    <ImagesPreviewBanner2 url={preview.slider4} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex flex-wrap gap-1">
                                            <div className="w-full md:w-[calc(40%-4px)] lg:w-[calc(40%-4px)]">
                                                <label htmlFor="slider5" class="flex flex-col items-center justify-center w-full max-h-[140px] min-h-[140px] rounded-lg cursor-pointer bg-white dark:bg-brand-900 hover:bg-brand-100 dark:hover:bg-brand-800">
                                                    <label for="first_name" class="block mb-2 text-sm font-medium text-brand-900 dark:text-white">Slider 5</label>
                                                    <div class="flex flex-col items-center justify-center">
                                                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-brand-900 dark:text-brand-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                                        <p class="text-xs text-brand-900 dark:text-brand-200">PNG, WEBP O JPEG (MAX. 1920px*1080px)</p>
                                                    </div>
                                                    <input id="slider5" accept="image/png,image/jpeg,image/webp" type="file" name="slider5" class="hidden" onChange={imageHandle} />
                                                </label>
                                            </div>
                                            <div class="w-full md:w-[60%] lg:w-[60%] flex justify-center items-center bg-white dark:bg-brand-900 min-w-[160px] max-h-[140px] min-h-[140px] rounded-lg">
                                                <div className="rounded-sm flex items-center justify-center">
                                                    <div className="">
                                                        <div className="">
                                                            <img src={`/images/logos/${configurations.sliders[4].url}`} id='prelogo' className="object-cover max-w-[160px] min-w-[160px] max-w-[160px] min-w-[160px] max-h-[90px] min-h-[90px]" />
                                                        </div>
                                                    </div>
                                                    {preview.slider5 && <IoChevronForward className="text-brand-300" size={32} />}
                                                    <ImagesPreviewBanner2 url={preview.slider5} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className="flex m-auto md:w-12/12 w-full">
                        <Button
                            type="submit"
                            disabled={isMutation.uloading ? true : false}
                            style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                            size='large' variant="contained"
                        >
                            {isMutation.uloading ? <Spinner /> : <IoReload size={24} className="mr-2" />}
                            {isMutation.uloading ? 'Guardando...' : 'ACTUALIZAR CONFIGURACION'}
                        </Button>
                    </div>
                </form>


            </div >

        </Wrapper >
    )
}
export default Configuration;