import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { Button, IconButton, Tooltip } from "@mui/material";
import { FaQuestion, FaWhatsapp } from 'react-icons/fa';
import { currencyFormat, message, PermiteSoloNumeros } from '../../utils/Funciones.js';
import { IoCloseSharp } from 'react-icons/io5';
import { MdPayment, MdVerified } from 'react-icons/md';
import AccountList from '../../components/home/AccountList';
import Spinner from '../../components/Spinner';
import Swal from 'sweetalert2';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import IziPayPaymentForm from '../../components/home/IziPayPaymentForm';
import KRGlue from '@lyracom/embedded-form-glue';
import { GiCash } from "react-icons/gi";
import { SET_TO_DEFAULT_RESPONSE, createBinancePayment, createIziPayPayment, createMercadoPagoPayment, createPaypalPayment, verifyIziPayPayment, verifyPaypalPayment } from '../../store/features/slices/paymentSlice';
import { SET_CASH_USER } from '../../store/features/slices/userSlice';
import Modal from '../../components/modal/Modal.jsx';
import { CURRENCY, CURRENCY_MODE } from '../../utils/Constants';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

const Recharge = ({ }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [s_PackSelected, SetPackSelected] = useState(0)
  const [s_tarifa, SetTarifa] = useState(0)
  const [currency_ve, setCurrencyVe] = useState(95)
  const [currency_mx, setCurrencyMx] = useState(20)
  const [s_PayMethod, SetPayMethod] = useState("")
  const [selectImageYape, SetSelectImageYape] = useState({ open: false, image: '' })
  const [selectImageBinance, SetSelectImageBinance] = useState({ open: false, image: '' })
  const dispatch = useDispatch();
  const [usdtToPen, setUsdtToPen] = useState(3.60);
  const [currency_pen, setCurrency_pen] = useState(3.60);
  const [isShow, setIsShow] = useState(false);
  const [formId, setFormId] = useState("");
  const [showPayAuto, setShowPayAuto] = useState(false);
  const [showPayManual, setShowPayManual] = useState(false);
  const endPoint = 'https://api.micuentaweb.pe';

  const {
    data,
    isMutation
  } = useSelector((state) => state.paymentReducer);
  const { userToken, user, configuration } = useSelector((state) => state.userReducer);

  useEffect(() => {
    const paymentId = searchParams.get('paymentId');
    const PayerID = searchParams.get('PayerID');

    if (paymentId && PayerID && !isMutation.loading) {
      dispatch(verifyPaypalPayment({ paymentId, PayerID, userId: userToken && user?.id }));
    }
  }, [dispatch, searchParams]);


  useEffect(() => {
    if (isMutation.success) {
      if (isMutation.extra.type === 'mercadopago') {
        window.location.href = data.init_point
      } else if (isMutation.extra.type === 'binance') {
        window.location.href = data.checkoutUrl
      } else if (isMutation.extra.type === 'izipay') {
        setIsShow(true);
        KRGlue.loadLibrary(endPoint, configuration?.pasarelas?.izipay?.public_key)
          .then(({ KR }) => KR.setFormConfig({
            formToken: data.formToken
            // formToken:data.formToken,
          }))
          .then(({ KR }) => KR.onSubmit(validatePayment))
          .then(({ KR }) => KR.attachForm("#form"))
          .then(({ KR, result }) => KR.showForm(result.formId))
          // })
          .catch(err => console.log(err))
      } else if (isMutation.extra.type === 'izipay_success') {
        dispatch(SET_CASH_USER({ type: 'INCREASE', amount: isMutation.extra.amount }))
        KRGlue.loadLibrary(endPoint, configuration?.pasarelas?.izipay?.public_key)
          .then(({ KR }) => {
            KR.removeForms();
            navigate('/manage-account/paymentshistory')
          })
          .catch(error => {
            console.error("Error al cargar la librería", error);
          });
      } else if (isMutation.extra.type === 'paypal_success') {
        dispatch(SET_CASH_USER({ type: 'INCREASE', amount: isMutation.extra.amount }))
        navigate('/manage-account/paymentshistory')
      }
      dispatch(SET_TO_DEFAULT_RESPONSE())
    }
  }, [dispatch, isMutation.success]);

  const addTarifaToRecharge = (fee) => {
    SetTarifa(fee)
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchExchangeRate = async () => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
        const data = await response.json();
        setUsdtToPen(data.rates[CURRENCY_MODE]);
        setCurrency_pen(data.rates['PEN']);
        setCurrencyMx(data.rates['MXN']);
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
      }
    };

    const interval = setInterval(fetchExchangeRate, 5000); // Fetch every 5 seconds
    fetchExchangeRate();
    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  const addPayMethodPayMethodToRecharge = (method) => {
    if (method === 'mercadopago') {
      SetPayMethod(method)
      addTarifaToRecharge(((Number(s_PackSelected) + parseFloat(1.18)) / (1 - parseFloat(3.99) / 100) - parseFloat(Number(s_PackSelected))).toFixed(2))
    } else if (method === 'stripe') {
      SetPayMethod(method)
      addTarifaToRecharge(((Number(s_PackSelected) + parseFloat(1.18)) / (1 - parseFloat(2.90) / 100) - parseFloat(Number(s_PackSelected))).toFixed(2))
    } else {
      SetPayMethod(method)
      addTarifaToRecharge(0)
    }
  };

  const [preview, setPreview] = useState({
    image: '',
  })

  const alertaConfirmar = (titulo, texto, icon, callback) => {
    Swal.fire({
      title: titulo,
      html: texto,
      icon: icon,
      showCancelButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
      cancelButtonText: 'Cancelar',
      confirmButtonColor: '#2C3E50',
      cancelButtonColor: '#CB4335'
    }).then((result) => {
      if (result.isConfirmed) {
        callback(result.isConfirmed)
      }
    })
  }

  const CloseSelectImageYape = () => {
    setPreview({ ...preview, image: '' })
    SetSelectImageYape({ ...selectImageYape, open: false, image: '' })
  }

  const CloseSelectImageBinance = () => {
    SetSelectImageBinance({ ...selectImageBinance, open: false, image: '' })
  }

  const pay = async () => {
    var packmin = 0
    var packmax = 0

    if (CURRENCY_MODE === 'USD') {
      packmin = 2
      packmax = 250
    } else if (CURRENCY_MODE === 'MXN') {
      packmin = 50
      packmax = 5000
    } else if (CURRENCY_MODE === 'COP') {
      packmin = 10000
      packmax = 200000
    } else {
      packmin = 4
      packmax = 1000
    }

    const recharge = [{
      name: 'PACK' + s_PackSelected,
      totalPay: s_PackSelected,
      _id: user?.id
    }]
    if (!userToken) {
      message(t('actionforbided'), 'error', 4);
    } else if (s_PackSelected <= 0) {
      message(t('needselectone'), 'error', 4);
    } else if (userToken && !user.reseller && (s_PackSelected < packmin || s_PackSelected > packmax)) {
      message(`El monto mínimo es ${packmin} y el monto máximo es ${packmax}`, 'error', 4);
    } else if (userToken && user.reseller && (s_PackSelected < packmin || s_PackSelected > packmax)) {
      message(`El monto mínimo es ${packmin} y el monto máximo es ${packmax}`, 'error', 4);
    } else if (s_PayMethod === "binance") {
      if (configuration?.pasarelas?.binance?.enabled && configuration?.pasarelas?.binance?.auto) {
        const response = await axios.get('https://api.ipify.org?format=json');
        const BinanceData = {
          amount: s_PackSelected,
          currency: 'USDT',
          usdtToPen: usdtToPen ? usdtToPen : 3.60,
          userId: user?.id,
          ip_location: response.data.ip ? response.data.ip : '0.0.0.0'
        }
        dispatch(createBinancePayment(BinanceData))
      } else if (configuration?.pasarelas?.binance?.enabled && !configuration?.pasarelas?.binance?.auto) {
        SetSelectImageBinance({ ...selectImageBinance, open: true })
      }

    } else if (s_PayMethod === "izipay") {
      const response = await axios.get('https://api.ipify.org?format=json');
      const monto = Number(s_PackSelected)
      var percent = CURRENCY_MODE === 'USD' ? 0.0409 : 0.0344
      var virtualfee = CURRENCY_MODE === 'USD' ? (0.69 / usdtToPen) : 0.69
      var montowf = ((monto * percent) + ((monto * percent) * 0.18) + virtualfee + (virtualfee * 0.18)) + monto
      var totalrealfee = ((montowf * percent) + ((montowf * percent) * 0.18) + virtualfee + (virtualfee * 0.18))
      var FeeFinal = CURRENCY_MODE === 'USD' ? Number(totalrealfee) : Number(totalrealfee)
      const IziPayData = {
        amount: monto,
        currency: CURRENCY_MODE,
        fee: FeeFinal.toFixed(2),
        userId: user?.id,
        email: user?.email,
        ip_location: response.data.ip ? response.data.ip : '0.0.0.0'
      }

      alertaConfirmar(`${t('agreeimportant')}!`, `${t('agreeizipay')} <br>${t('fees')}: <b>+${currencyFormat(FeeFinal)}</b><br> ${t('wantcontinued')}?`, 'question', (res_alert) => {
        if (res_alert === true) {
          dispatch(createIziPayPayment(IziPayData))
        }
      })
    } else if (s_PayMethod === "yape" ||
      s_PayMethod === "plin" ||
      s_PayMethod === "pagomovil" ||
      s_PayMethod === "nequi" ||
      s_PayMethod === "banamex" ||
      s_PayMethod === "yolopago" ||
      s_PayMethod === "tigomoney" ||
      s_PayMethod === "spin" ||
      (s_PayMethod === "venmo" && !configuration?.pasarelas?.venmo?.auto) ||
      (s_PayMethod === "cashapp" && !configuration?.pasarelas?.cashapp?.auto) ||
      (s_PayMethod === "zelle" && !configuration?.pasarelas?.zelle?.auto) ||
      (s_PayMethod === "transferencia" && !configuration?.pasarelas?.transferencia?.auto) ||
      (s_PayMethod === "paypal" && !configuration?.pasarelas?.paypal?.auto) ||
      s_PayMethod === "yasta") {
      if (s_PayMethod === "pagomovil") {
        fetch('https://pydolarve.org/api/v1/dollar?page=enparalelovzla')
          .then(response => response.json())
          .then(data => setCurrencyVe(data?.monitors?.enparalelovzla?.price));

      }
      SetSelectImageYape({ ...selectImageYape, open: true })
    } else if (s_PayMethod === "stripe") {
      alertaConfirmar(`${t('agreeimportant')}!`, `${t('agreestripe')} <br>${t('fees')}: <b>+${currencyFormat(Number(s_tarifa))}</b><br> ${t('wantcontinued')}?`, 'question', (res_alert) => {
        //doPayment({ recharge: recharge });
      })
    } else if (s_PayMethod === "mercadopago") {

      const tarifasCredito = {
        "USD": { porcentaje: 5.4, iva: 18, tarifaFija: 0.30 },
        "ARS": { porcentaje: 6.99, iva: 18, tarifaFija: 0 },
        "BRL": { porcentaje: 4.99, iva: 18, tarifaFija: 0.40 },
        "MXN": { porcentaje: 3.49, iva: 16, tarifaFija: 4.64 },
        "COP": { porcentaje: 5.0, iva: 18, tarifaFija: 800 },
        "CLP": { porcentaje: 4.99, iva: 18, tarifaFija: 300 },
        "PEN": { porcentaje: 3.99, iva: 18, tarifaFija: 1.18 }
      };

      function calcularComision(monto, moneda = "USD") {
        if (!tarifasCredito[moneda]) {
          throw new Error("⚠️ Moneda no soportada");
        }

        const { porcentaje, tarifaFija, iva } = tarifasCredito[moneda];

        // Cálculo de la comisión
        const montoBruto = (monto + tarifaFija) / (1 - ((porcentaje / 100) * (1 + (iva / 100))));

        return {
          moneda,
          montoOriginal: monto.toFixed(2),
          porcentajeComision: porcentaje,
          tarifaFija,
          comision: montoBruto - monto,
          montoRecibido: monto.toFixed(2)
        };
      }

      try {
        const resultado = calcularComision(Number(s_PackSelected), CURRENCY_MODE);
        alertaConfirmar(`${t('agreeimportant')}!`, `${t('agreemercadopago')} <br>${t('fees')}: <b>+${currencyFormat(Number(resultado.comision))}</b><br> ${t('wantcontinued')}?`, 'question', (res_alert) => {
          if (res_alert === true) {
            const rechargeMercadoPago = {
              fee: resultado.comision.toFixed(2),
              conversion: usdtToPen,
              name: 'PACK' + s_PackSelected,
              totalPay: Number(s_PackSelected),
              currency: CURRENCY_MODE,
              userId: user?.id
            }
            dispatch(createMercadoPagoPayment({ recharge: rechargeMercadoPago }));
          }
        })

      } catch (error) {
        console.error(error.message);
      }

    } else if (s_PayMethod === "paypal") {
      const response = await fetch('https://api.exchangerate-api.com/v4/latest/USD');
      const data = await response.json();
      var conversion = data.rates[CURRENCY_MODE]

      const tarifas = {
        "USD": { variable: 0.054, fija: 0.30 },  // 🇺🇸 EE.UU.
        "MXN": { variable: 0.039, fija: 4.00 },  // 🇲🇽 México (MXN)
        "EUR": { variable: 0.049, fija: 0.35 },  // 🇪🇸 España (EUR)
        "PEN": { variable: 0.054, fija: 1.00 },  // 🇵🇪 Perú (PEN)
        "ARS": { variable: 0.059, fija: 0.75 },  // 🇦🇷 Argentina (ARS)
        "COP": { variable: 0.054, fija: 1.000 }, // 🇨🇴 Colombia (COP)
      };

      // Obtener tarifa según el país
      const tarifa = tarifas["USD"]; // Si no se encuentra, usa US por defecto

      // Calcular el monto a enviar
      const montoAEnviar = ((Number(s_PackSelected)) + tarifa.fija) / (1 - tarifa.variable);
      const fees = montoAEnviar - (Number(s_PackSelected))

      //let fees = CURRENCY_MODE === 'USD' ? ((Number(s_PackSelected) * 0.054) + 0.30) : ((((Number(s_PackSelected) / conversion) * 0.054) + 0.30) * conversion)

      alertaConfirmar(`Acuerdo importante!`, `El monto actual no incluye tarifas de Paypal. <br>${t('fees')}: <b>+${currencyFormat(Number(fees))}</b><br> ${t('wantcontinued')}?`, 'question', (res_alert) => {
        if (res_alert === true) {
          const data = {
            fee: fees,
            conversion: conversion,
            totalPay: Number(s_PackSelected),
            currency: CURRENCY_MODE,
            userId: user?.id
          }
          dispatch(createPaypalPayment(data));
        }
      })
    }
  };

  const HandleChangeMontoManual = (e) => {
    if (s_PayMethod === 'mercadopago') {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(((Number(e.target.value) + parseFloat(1.18)) / (1 - parseFloat(3.99) / 100) - parseFloat(Number(e.target.value))).toFixed(2))
    } else if (s_PayMethod === 'stripe') {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(((Number(e.target.value) + parseFloat(1.18)) / (1 - parseFloat(2.90) / 100) - parseFloat(Number(e.target.value))).toFixed(2))
    } else if (s_PayMethod === 'yape') {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(0)
    } else if (s_PayMethod === 'binance') {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(0)
    } else {
      SetPackSelected(e.target.value)
      addTarifaToRecharge(0)
    }
  }

  const validatePayment = (resp) => {
    dispatch(verifyIziPayPayment({ clientAnswer: resp.clientAnswer, hash: resp.hash, hashKey: resp.hashKey }))
  }

  const onCloseFormPaymentIziPay = () => {
    setIsShow(!isShow)
    KRGlue.loadLibrary(endPoint, configuration?.pasarelas?.izipay?.public_key)
      .then(({ KR }) => {
        KR.removeForms();
      })
      .catch(error => {
        console.error("Error al cargar la librería", error);
      });
  }

  return (
    <AccountList>
      {/* HEADER PAGES */}
      <div className="fixed mt-8 p-4 flex flex-wrap text-brand-900 dark:text-brand-100 uppercase w-[calc(100%-1.50rem)] md:w-[calc(100%-1.50rem)] lg:w-[calc(100%-26.50rem)] bg-gradient-to-r from-white dark:from-brand-900 backdrop-blur-lg z-30 rounded-b-xl">
        <div className="w-full h-14 text-lg md:text-2xl flex items-center">
          <div className="flex items-center w-9/12 md:w-6/12">
            <GiCash size={65} style={{ marginRight: 10 }} />
            {t('recharge')}
          </div>
        </div>
      </div>

      {
        isMutation.loading &&
        <div className="fixed inset-0 flex items-center justify-center bg-black dark:bg-black z-50 bg-opacity-70 backdrop-blur-[3px] dark:bg-opacity-40">
          <div className="flex flex-wrap justify-center gap-4 w-44">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-200 dark:border-gray-200"></div>
            <div>Procesando por favor espere ...</div>
          </div>
        </div>
      }

      <Modal
        open={selectImageYape.open}
        onClose={CloseSelectImageYape}
        headerTitle={`${t('pay')} ${CURRENCY_MODE === 'USD' ? ((s_PayMethod === 'yape' || s_PayMethod === 'plin') ? 'S/ ' + (Number(currency_pen) * Number(s_PackSelected)).toFixed(2) : s_PayMethod === 'spin' ? 'MX$ ' + (Number(currency_mx) * Number(s_PackSelected)).toFixed(2) : s_PayMethod === 'transferencia' ? CURRENCY + (Number(s_PackSelected)).toFixed(2) : s_PayMethod === 'cashapp' ? CURRENCY + (Number(s_PackSelected)).toFixed(2) : s_PayMethod === 'zelle' ? CURRENCY + (Number(s_PackSelected)).toFixed(2) : s_PayMethod === 'venmo' ? CURRENCY + (Number(s_PackSelected)).toFixed(2) : s_PayMethod === 'pagomovil' ? 'Bs ' + (Number(currency_ve) * Number(s_PackSelected)).toFixed(2) : s_PayMethod === 'paypal' ? 'USD ' + (((Number(s_PackSelected)) + 0.30) / (1 - 0.054)).toFixed(2) : s_PayMethod === 'nequi' ? 'COL$ ' + (Number(s_PackSelected) * 4500).toFixed(2) : s_PayMethod === 'banamex' ? 'MX$ ' + (Number(s_PackSelected) * 25).toFixed(2) : CURRENCY) : (s_PayMethod === 'paypal' ? 'USD ' + (((Number(s_PackSelected) / usdtToPen) + 0.30) / (1 - 0.054)).toFixed(2) : s_PayMethod === 'spin' ? 'MX$ ' + ((Number(s_PackSelected) / Number(usdtToPen)) * Number(currency_mx)).toFixed(2) : CURRENCY + Number(s_PackSelected).toFixed(2))} ${t('whit')} ${s_PayMethod === 'pagomovil' ? 'PAGO MOVIL' : s_PayMethod === 'nequi' ? 'NEQUI' : s_PayMethod === 'banamex' ? 'BANAMEX' : s_PayMethod === 'yolopago' ? 'YOLOPAGO' : s_PayMethod === 'tigomoney' ? 'TIGOMONEY' : s_PayMethod === 'yasta' ? 'YASTA' : s_PayMethod === 'plin' ? 'PLIN' : s_PayMethod === 'spin' ? 'SPIN' : s_PayMethod === 'paypal' ? 'PAYPAL' : s_PayMethod === 'zelle' ? 'ZELLE' : s_PayMethod === 'cashapp' ? 'CASH APP' : s_PayMethod === 'venmo' ? 'VENMO' : s_PayMethod === 'transferencia' ? 'TRANSFERENCIA' : 'YAPE'}`}
        size="base"
      >
        <div className="realtive m-auto w-full">
          <form className='w-full flex flex-wrap p-3 mt-5'>
            <div className="relative w-full flex items-center justify-center mb-6 mt-4">
              <label className="absolute -top-10 text-2xl text-brand-900 dark:text-brand-100">{s_PayMethod === 'banamex' ? 'Información de cuentas ' : t('scan') + ' QR'}</label>
              <LazyLoadImage
                className="rounded-lg w-96 object-cover"
                alt={`No image Qr Yape`}
                effect="opacity"
                src={`/images/paymethods/${s_PayMethod === 'pagomovil' ? 'venezuela.png' : s_PayMethod === 'nequi' ? 'colombia.png' : s_PayMethod === 'banamex' ? 'mexico.png' : s_PayMethod === 'yolopago' ? 'yolopago.jpg' : s_PayMethod === 'tigomoney' ? 'tigomoney.jpg' : s_PayMethod === 'yasta' ? 'yasta.jpg' : s_PayMethod === 'plin' ? 'qr-plin.jpg' : s_PayMethod === 'spin' ? 'spin-qr.png' : s_PayMethod === 'zelle' ? 'zelle-qr.png' : s_PayMethod === 'venmo' ? 'venmo-qr.png' : s_PayMethod === 'cashapp' ? 'cashapp-qr.png' : s_PayMethod === 'transferencia' ? 'transferencia.jpg' : s_PayMethod === 'paypal' ? 'paypal-qr.png' : 'qr-yape.jpg'}`}
              />
            </div>
            <div className='text-red-600 dark:text-red-400 text-2xl font-semibold'>{s_PayMethod === 'yape' ? t('yapeinfo') : 'DESPUÉS DE REALIZAR EL PAGO, POR FAVOR ENVIAR LA CAPTURA AL WHATSAPP A FIN DE RECARGAR SU SALDO'}.</div>

            <div className="relative w-full mb-3 mt-8 flex flex-wrap justify-center">
              <label className='w-full text-center font-bold'>{t('contactus')}</label>
              <a href={`https://wa.me//${configuration?.social?.whatsapp?.replace(/\s+/g, '').replace('+', '')}?text=Hola hice una recarga por ${s_PayMethod === 'pagomovil' ? 'PAGO MOVIL' : s_PayMethod === 'nequi' ? 'NEQUI' : s_PayMethod === 'banamex' ? 'BANAMEX' : s_PayMethod === 'transferencia' ? 'TRANSFERENCIA' : s_PayMethod === 'yolopago' ? 'YOLOPAGO' : s_PayMethod === 'zelle' ? 'ZELLE' : s_PayMethod === 'venmo' ? 'VENMO' : s_PayMethod === 'cashapp' ? 'CASH APP' : s_PayMethod === 'tigomoney' ? 'TIGOMONEY' : s_PayMethod === 'yasta' ? 'YASTA' : s_PayMethod === 'plin' ? 'PLIN' : s_PayMethod === 'spin' ? 'SPIN' : s_PayMethod === 'paypal' ? 'PAYPAL' : 'YAPE'} con el monto de ${CURRENCY_MODE === 'USD' ? ((s_PayMethod === 'yape' || s_PayMethod === 'plin') ? 'S/ ' + (Number(currency_pen) * Number(s_PackSelected)).toFixed(2) : s_PayMethod === 'zelle' ? CURRENCY + (Number(s_PackSelected)).toFixed(2) : s_PayMethod === 'venmo' ? CURRENCY + (Number(s_PackSelected)).toFixed(2) : s_PayMethod === 'transferencia' ? CURRENCY + (Number(s_PackSelected)).toFixed(2) : s_PayMethod === 'cashapp' ? CURRENCY + (Number(s_PackSelected)).toFixed(2) : s_PayMethod === 'pagomovil' ? 'Bs ' + (Number(currency_ve) * Number(s_PackSelected)).toFixed(2) : s_PayMethod === 'paypal' ? 'USD ' + (((Number(s_PackSelected)) + 0.30) / (1 - 0.054)).toFixed(2) : s_PayMethod === 'nequi' ? 'COL$ ' + (Number(s_PackSelected) * 4500).toFixed(2) : s_PayMethod === 'banamex' ? 'MX$ ' + (Number(s_PackSelected) * 25).toFixed(2) : CURRENCY) + ' que equivale a ' + Number(s_PackSelected) + ' USD' : (s_PayMethod === 'paypal' ? 'USD ' + (((Number(s_PackSelected) / usdtToPen) + ((Number(s_PackSelected) / usdtToPen) * 0.054) + 0.30)).toFixed(2) + ' que equivale a ' + CURRENCY + Number(s_PackSelected).toFixed(2) : CURRENCY + Number(s_PackSelected).toFixed(2))}, mi correo es : ${userToken && user.email}, por favor podría verificar, gracias. `} target="_blank" className='flex items-center cursor-pointer flex items-center'>
                <div className='z-20 bg-green-600 rounded-full flex items-center justify-center p-5 shadow-md shadow-brand-950'><FaWhatsapp size={45} /></div>
                <div className='bg-green-600 pt-5 pb-5 pr-5 pl-10 -ml-5 rounded-lg text-lg font-bold'>{t('cometowhatsapp')}</div>
              </a>
            </div>
          </form>
        </div>
      </Modal>


      <Modal
        open={selectImageBinance.open}
        onClose={CloseSelectImageBinance}
        headerTitle={`${t('pay')} ${CURRENCY_MODE === 'USD' ? Number(s_PackSelected) : (Number(s_PackSelected) / Number(usdtToPen)).toFixed(2)} USDT ${t('whit')} BINANCE`}
        size="base"
      >
        <div className="realtive m-auto w-full">
          <form className='w-full flex flex-wrap p-3 mt-5'>
            <div className="relative w-full flex items-center justify-center mb-6 mt-4">
              <label className="absolute -top-10 text-2xl">{t('scan')} QR</label>
              <LazyLoadImage
                className="rounded-lg w-96 object-cover"
                alt={`No image Qr Yape`}
                effect="opacity"
                src={`/images/paymethods/qr-binance.jpeg`}
              />
            </div>
            <div className='text-yellow-600 dark:text-yellow-300 text-2xl font-semibold'>{t('binanceinfo')}</div>

            <div className="relative w-full mb-3 mt-8 flex flex-wrap justify-center">
              <label className='w-full text-center font-bold'>{t('contactus')}</label>
              <a href={`https://wa.me//${configuration?.social?.whatsapp?.replace(/\s+/g, '').replace('+', '')}?text=Hola hice una recarga por binance con el monto de ${currencyFormat(Number(s_PackSelected))} o ${(Number(s_PackSelected) / Number(usdtToPen)).toFixed(2)} USDT, mi correo es : ${userToken && user.email}, por favor podría verificar, gracias. `} target="_blank" className='flex items-center cursor-pointer flex items-center'>
                <div className='z-20 bg-green-600 rounded-full flex items-center justify-center p-5 shadow-md shadow-brand-950'><FaWhatsapp size={45} /></div>
                <div className='bg-green-600 pt-5 pb-5 pr-5 pl-10 -ml-5 rounded-lg text-lg font-bold'>{t('cometowhatsapp')}</div>
              </a>
            </div>
          </form>
        </div>
      </Modal>

      <div className="flex justify-center w-full mt-[125px]">
        <div className="w-full md:w-full lg:w-8/12">
          <div className="flex flex-wrap gap-7">
            <div className="w-full flex justify-center relative">
              <div className="w-full md:w-full lg:w-4/12 min-w-[250px]">
                <label for="first_name" class="block mb-2 text-xl font-bold text-brand-900 dark:text-white">{t('amount')}</label>
                <input style={{ fontFamily: 'Outfit, sans-serif' }} autoFocus type="number" maxLength={4} minLength={1} id="monto" className="custom-style-input pl-8 !h-32 w-full text-7xl"
                  required
                  value={s_PackSelected}
                  onChange={HandleChangeMontoManual}
                  autoComplete="off"
                  onKeyPress={PermiteSoloNumeros}
                />
              </div>
            </div>

            <div className="w-full">
              <label for="first_name" class="w-full block text-lg font-bold text-brand-900 dark:text-white text-center border-b border-brand-200 dark:border-brand-800 pb-5 pt-5">{t('rechangecash')} <b>MANUAL</b>
                <Tooltip title={
                  <div style={{ display: 'block' }}>
                    <div className='w-full mb-2 text-center font-semibold border-b-[2px] border-brand-600'>{t('rechangecash')} MANUAL</div>
                    <div className='text-brand-100 dark:text-brand-100 text-sm font-normal'>{t('rechargemanualinfo')}</div>
                  </div>}

                  PopperProps={{
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        fontSize: 12.5,
                      }
                    }
                  }} arrow>
                  <IconButton color='primary' className='w-14 h-14 rounded-full bg-brand-800 flex items-center justify-center ml-10'><FaQuestion size={24} className='' /></IconButton></Tooltip>
              </label>
              <div className='w-full flex items-center justify-center mt-3'>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full md:w-full">

                  {
                    (configuration?.pasarelas?.yape?.enabled && !configuration?.pasarelas?.yape?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('yape')}
                      className={`relative flex items-center h-24 cursor-pointer rounded-xl bg-white dark:bg-brand-900 text-white ${s_PayMethod === 'yape' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'yape' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image yape}`}
                          effect="opacity"
                          src={`/images/paymethods/yape.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold ${s_PayMethod === 'yape' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>YAPE</div>
                        <div className={`text-xs font-bold ${s_PayMethod === 'yape' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>BCP</div>
                      </div>
                    </div>
                  }

                  {
                    (configuration?.pasarelas?.plin?.enabled && !configuration?.pasarelas?.plin?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('plin')}
                      className={`relative flex items-center h-24 cursor-pointer rounded-xl bg-white dark:bg-brand-900 text-white ${s_PayMethod === 'plin' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'plin' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image plin}`}
                          effect="opacity"
                          src={`/images/paymethods/plin.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold ${s_PayMethod === 'plin' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>PLIN</div>
                        <div className={`text-xs font-bold ${s_PayMethod === 'plin' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>SCOTIABANK - INTERBANK - BBVA</div>
                      </div>
                    </div>
                  }

                  {
                    (configuration?.pasarelas?.spin?.enabled && !configuration?.pasarelas?.spin?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('spin')}
                      className={`relative flex items-center h-24 cursor-pointer rounded-xl bg-white dark:bg-brand-900 text-white ${s_PayMethod === 'spin' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'spin' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className="absolute bottom-2 right-2">
                        <LazyLoadImage
                          className="object-cover rounded-lg h-6 w-8"
                          alt={`No image`}
                          effect="opacity"
                          src={`/images/flags/4x3/MX.svg`}
                        />
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image spin}`}
                          effect="opacity"
                          src={`/images/paymethods/spin.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold ${s_PayMethod === 'spin' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>SPIN</div>
                        <div className={`text-xs font-bold ${s_PayMethod === 'spin' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>OXXO</div>
                      </div>
                    </div>
                  }

                  {
                    (configuration?.pasarelas?.paypal?.enabled && !configuration?.pasarelas?.paypal?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('paypal')}
                      className={`relative flex items-center h-24 cursor-pointer rounded-xl bg-white dark:bg-brand-900 text-white ${s_PayMethod === 'paypal' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'paypal' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image paypal}`}
                          effect="opacity"
                          src={`/images/paymethods/paypal.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold ${s_PayMethod === 'paypal' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>PAYPAL</div>
                        <div className={`text-xs font-bold ${s_PayMethod === 'paypal' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>DEBIT & CREDIT CARD</div>
                      </div>
                    </div>
                  }

                  {
                    (configuration?.pasarelas?.pagomovil?.enabled && !configuration?.pasarelas?.pagomovil?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('pagomovil')}
                      className={`relative flex items-center h-24 cursor-pointer rounded-xl bg-white dark:bg-brand-900 text-white ${s_PayMethod === 'pagomovil' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'pagomovil' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className="absolute bottom-2 right-2">
                        <LazyLoadImage
                          className="object-cover rounded-lg h-6 w-8"
                          alt={`No image`}
                          effect="opacity"
                          src={`/images/flags/4x3/VE.svg`}
                        />
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image pagomovil}`}
                          effect="opacity"
                          src={`/images/paymethods/pagomovil.png`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold ${s_PayMethod === 'pagomovil' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>PAGO MOVIL</div>
                        <div className={`text-xs font-bold ${s_PayMethod === 'pagomovil' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>VENEZUELA</div>
                      </div>
                    </div>
                  }

                  {
                    (configuration?.pasarelas?.nequi?.enabled && !configuration?.pasarelas?.nequi?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('nequi')}
                      className={`relative flex items-center h-24 cursor-pointer rounded-xl bg-white dark:bg-brand-900 text-white ${s_PayMethod === 'nequi' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'nequi' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className="absolute bottom-2 right-2">
                        <LazyLoadImage
                          className="object-cover rounded-lg h-6 w-8"
                          alt={`No image`}
                          effect="opacity"
                          src={`/images/flags/4x3/CO.svg`}
                        />
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image nequi}`}
                          effect="opacity"
                          src={`/images/paymethods/nequi.png`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold ${s_PayMethod === 'nequi' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>NEQUI</div>
                        <div className={`text-xs font-bold ${s_PayMethod === 'nequi' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>COLOMBIA</div>
                      </div>
                    </div>

                  }

                  {
                    (configuration?.pasarelas?.banamex?.enabled && !configuration?.pasarelas?.banamex?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('banamex')}
                      className={`relative flex items-center h-24 cursor-pointer rounded-xl bg-white dark:bg-brand-900 text-white ${s_PayMethod === 'banamex' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'banamex' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className="absolute bottom-2 right-2">
                        <LazyLoadImage
                          className="object-cover rounded-lg h-6 w-8"
                          alt={`No image`}
                          effect="opacity"
                          src={`/images/flags/4x3/MX.svg`}
                        />
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image banamex}`}
                          effect="opacity"
                          src={`/images/paymethods/banamex.png`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold ${s_PayMethod === 'banamex' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>BANAMEX</div>
                        <div className={`text-xs font-bold ${s_PayMethod === 'banamex' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>MÉXICO</div>
                      </div>
                    </div>
                  }

                  {
                    (configuration?.pasarelas?.yolopago?.enabled && !configuration?.pasarelas?.yolopago?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('yolopago')}
                      className={`relative flex items-center h-24 cursor-pointer rounded-xl bg-white dark:bg-brand-900 text-white ${s_PayMethod === 'yolopago' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'yolopago' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full object-cover"
                          alt={`No image yolopago}`}
                          effect="opacity"
                          src={`/images/paymethods/yolopago.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold ${s_PayMethod === 'yolopago' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>YOLOPAGO</div>
                        <div className={`text-xs font-bold ${s_PayMethod === 'yolopago' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>WALLET</div>
                      </div>
                    </div>
                  }

                  {
                    (configuration?.pasarelas?.tigomoney?.enabled && !configuration?.pasarelas?.tigomoney?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('tigomoney')}
                      className={`relative flex items-center h-24 cursor-pointer rounded-xl bg-white dark:bg-brand-900 text-white ${s_PayMethod === 'tigomoney' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'tigomoney' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full object-cover"
                          alt={`No image tigomoney}`}
                          effect="opacity"
                          src={`/images/paymethods/tigomoney.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold ${s_PayMethod === 'tigomoney' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>TIGOMONEY</div>
                        <div className={`text-xs font-bold ${s_PayMethod === 'tigomoney' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>WALLET</div>
                      </div>
                    </div>
                  }

                  {
                    (configuration?.pasarelas?.yasta?.enabled && !configuration?.pasarelas?.yasta?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('yasta')}
                      className={`relative flex items-center h-24 cursor-pointer rounded-xl bg-white dark:bg-brand-900 text-white ${s_PayMethod === 'yasta' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'yasta' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full object-cover"
                          alt={`No image yasta}`}
                          effect="opacity"
                          src={`/images/paymethods/yasta.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold ${s_PayMethod === 'yasta' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>YASTA</div>
                        <div className={`text-xs font-bold ${s_PayMethod === 'yasta' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>BANCO UNION</div>
                      </div>
                    </div>
                  }

                  {
                    (configuration?.pasarelas?.binance?.enabled && !configuration?.pasarelas?.binance?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('binance')}
                      className={`relative flex items-center h-24 bg-white dark:bg-brand-900 text-white cursor-pointer rounded-xl ${s_PayMethod === 'binance' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'binance' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image yape}`}
                          effect="opacity"
                          src={`/images/paymethods/binance.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold  ${s_PayMethod === 'binance' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>BINANCE</div>
                        <div className={`text-xs font-bold  ${s_PayMethod === 'binance' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>USDT</div>
                      </div>
                    </div>

                  }

                  {
                    (configuration?.pasarelas?.cashapp?.enabled && !configuration?.pasarelas?.cashapp?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('cashapp')}
                      className={`relative flex items-center h-24 bg-white dark:bg-brand-900 text-white cursor-pointer rounded-xl ${s_PayMethod === 'cashapp' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'cashapp' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image yape}`}
                          effect="opacity"
                          src={`/images/paymethods/cashapp.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold  ${s_PayMethod === 'cashapp' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>CASH APP</div>
                        <div className={`text-xs font-bold  ${s_PayMethod === 'cashapp' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>cash app</div>
                      </div>
                    </div>

                  }

                  {
                    (configuration?.pasarelas?.venmo?.enabled && !configuration?.pasarelas?.venmo?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('venmo')}
                      className={`relative flex items-center h-24 bg-white dark:bg-brand-900 text-white cursor-pointer rounded-xl ${s_PayMethod === 'venmo' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'venmo' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image yape}`}
                          effect="opacity"
                          src={`/images/paymethods/venmo.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold  ${s_PayMethod === 'venmo' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>VENMO</div>
                        <div className={`text-xs font-bold  ${s_PayMethod === 'venmo' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>venmo</div>
                      </div>
                    </div>

                  }

                  {
                    (configuration?.pasarelas?.zelle?.enabled && !configuration?.pasarelas?.zelle?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('zelle')}
                      className={`relative flex items-center h-24 bg-white dark:bg-brand-900 text-white cursor-pointer rounded-xl ${s_PayMethod === 'zelle' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'zelle' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image yape}`}
                          effect="opacity"
                          src={`/images/paymethods/zelle.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold  ${s_PayMethod === 'zelle' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>ZELLE</div>
                        <div className={`text-xs font-bold  ${s_PayMethod === 'zelle' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>zelle</div>
                      </div>
                    </div>

                  }


                  {
                    (configuration?.pasarelas?.transferencia?.enabled && !configuration?.pasarelas?.transferencia?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('transferencia')}
                      className={`relative flex items-center h-24 bg-white dark:bg-brand-900 text-white cursor-pointer rounded-xl ${s_PayMethod === 'transferencia' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'transferencia' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image yape}`}
                          effect="opacity"
                          src={`/images/paymethods/transferencia.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold  ${s_PayMethod === 'transferencia' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>TRANSFERENCIA</div>
                        <div className={`text-xs font-bold  ${s_PayMethod === 'transferencia' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>Debito</div>
                      </div>
                    </div>

                  }
                </div>
              </div>
              {((configuration?.pasarelas?.mercadopago?.enabled && configuration?.pasarelas?.mercadopago?.auto) ||
                (configuration?.pasarelas?.binance?.enabled && configuration?.pasarelas?.binance?.auto) ||
                (configuration?.pasarelas?.izipay?.enabled && configuration?.pasarelas?.izipay?.auto) ||
                (configuration?.pasarelas?.paypal?.enabled && configuration?.pasarelas?.paypal?.auto)) &&
                <label for="first_name" class="w-full block text-lg font-bold text-brand-900 dark:text-white text-center border-b border-brand-200 dark:border-brand-800 pb-5 pt-5">Recarga de saldo <b>AUTOMÁTICO</b>
                  <Tooltip title={
                    <div style={{ display: 'block' }}>
                      <div className='w-full mb-2 text-center font-semibold border-b-[2px] border-brand-600'>{t('rechangecash')} {t('automaticupper')}</div>

                      <div className='text-brand-100 dark:text-brand-100 text-sm font-normal'>{t('rechargeautomaticinfo')}</div>
                    </div>}

                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          fontSize: 12.5,
                        }
                      }
                    }} arrow><IconButton color='primary' className='w-14 h-14 rounded-full bg-brand-800 flex items-center justify-center ml-10'><FaQuestion size={24} className='' /></IconButton></Tooltip>
                </label>
              }


              <div className='w-full flex items-center justify-center mt-3'>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full">

                  {
                    (configuration?.pasarelas?.mercadopago?.enabled && configuration?.pasarelas?.mercadopago?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('mercadopago')}
                      className={`relative flex items-center h-24 bg-white dark:bg-brand-900 text-white cursor-pointer rounded-xl ${s_PayMethod === 'mercadopago' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'mercadopago' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image mercadopago}`}
                          effect="opacity"
                          src={`/images/paymethods/mercadopago.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold  ${s_PayMethod === 'mercadopago' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>MERCADOPAGO</div>
                        <div className={`text-xs font-bold  ${s_PayMethod === 'mercadopago' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>MERCADOLIBRE</div>
                      </div>
                    </div>

                  }

                  {
                    (configuration?.pasarelas?.binance?.enabled && configuration?.pasarelas?.binance?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('binance')}
                      className={`relative flex items-center h-24 bg-white dark:bg-brand-900 text-white cursor-pointer rounded-xl ${s_PayMethod === 'binance' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'binance' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image yape}`}
                          effect="opacity"
                          src={`/images/paymethods/binance.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold  ${s_PayMethod === 'binance' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>BINANCE</div>
                        <div className={`text-xs font-bold  ${s_PayMethod === 'binance' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>USDT</div>
                      </div>
                    </div>
                  }

                  {
                    (configuration?.pasarelas?.izipay?.enabled && configuration?.pasarelas?.izipay?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('izipay')}
                      className={`relative flex items-center h-24 bg-white dark:bg-brand-900 text-white cursor-pointer rounded-xl ${s_PayMethod === 'izipay' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'izipay' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image izipay}`}
                          effect="opacity"
                          src={`/images/paymethods/izipay.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold  ${s_PayMethod === 'izipay' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>IZIPAY</div>
                        <div className={`text-xs font-bold  ${s_PayMethod === 'izipay' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>DEBIT & CREDIT CARD</div>
                      </div>
                    </div>
                  }

                  {
                    (configuration?.pasarelas?.paypal?.enabled && configuration?.pasarelas?.paypal?.auto) &&
                    <div onClick={(e) => addPayMethodPayMethodToRecharge('paypal')}
                      className={`relative flex items-center h-24 bg-white dark:bg-brand-900 text-white cursor-pointer rounded-xl ${s_PayMethod === 'paypal' && '!bg-lightPrimary dark:!bg-darkPrimary'}`}>
                      <div className="absolute top-2 right-2">
                        {s_PayMethod === 'paypal' && <MdVerified size={28} className="text-green-400 dark:text-green-400" />}
                      </div>
                      <div className='w-[65px] h-[65px] flex items-center justify-center'>
                        <LazyLoadImage
                          className="z-10 w-[45px] h-[45px] rounded-full flex"
                          alt={`No image paypal}`}
                          effect="opacity"
                          src={`/images/paymethods/paypal.webp`}
                        />
                      </div>
                      <div className='grid grid-wrap w-[calc(100%-65)]'>
                        <div className={`text-lg font-bold  ${s_PayMethod === 'paypal' ? 'text-brand-50' : 'text-brand-900 dark:text-brand-50'}`}>PAYPAL</div>
                        <div className={`text-xs font-bold  ${s_PayMethod === 'paypal' ? 'text-brand-300' : 'text-brand-700 dark:text-brand-300'}`}>DEBIT & CREDIT CARD</div>
                      </div>
                    </div>

                  }

                  <div id="popup-modal" tabindex="-1" class={`${isShow ? 'fixed block' : 'hidden'} flex items-center justify-center inset-0 bg-brand-600 bg-opacity-50 overflow-y-auto h-full w-full overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                    <div class="relative p-4 w-full max-w-md max-h-full">
                      <div class="relative bg-white rounded-lg shadow dark:bg-brand-700 flex items-center justify-center p-2">
                        <button type="button" class="absolute top-3 end-2.5 text-brand-400 bg-transparent hover:bg-brand-200 hover:text-brand-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-brand-600 dark:hover:text-white" data-modal-hide="popup-modal">
                          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                          </svg>
                          <div className="w-12 h-12 rounded-full bg-red-600 hover:bg-red-500">
                            <IconButton color="error" onClick={onCloseFormPaymentIziPay}>
                              <IoCloseSharp
                                className="text-brand-200"
                                size={28}
                              />
                            </IconButton>
                          </div>
                        </button>
                        <div className='relative p-3'>
                          <div className='w-36 h-16'>
                            <LazyLoadImage
                              className="z-10 w-18 h-18"
                              alt={`No image izipay}`}
                              effect="opacity"
                              src={`/images/logos/izipaylogo-2.png`}
                            />
                          </div>
                          <div class="flex items-center justify-center w-full p-3 relative m-auto">
                            <div className="d-flex justify-content-center">
                              <div id="myDIV" className="formulario">
                                <div id="form">
                                  {/* Formulario de pago incrustado */}
                                  <IziPayPaymentForm />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex mb-6 w-full">
              <div class="flex-auto flex space-x-4">
                <Button disabled={(isMutation.loading || s_PackSelected < 1 || s_PayMethod === "" || isMutation.loading) ? true : false}
                  sx={{
                    "&.Mui-disabled": {
                      background: "#525252",
                      color: "#a3a3a3"
                    }
                  }}
                  onClick={pay}
                  type="button" style={{ width: '100%', height: 65, marginLeft: 'auto', marginRight: 'auto' }}
                  size='large' variant="contained" color="primary" startIcon={<MdPayment size={24} className={`mr-2 ${isMutation.loading && 'hidden'}`} />}>{isMutation.loading ? <><Spinner w={'35px'} h={'35px'} /> {t('proccessing')}... </> : t('gotopay')}</Button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </AccountList>
  );
};

export default Recharge;